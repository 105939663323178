import React, { Fragment } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const directions = (
  <Fragment>
    <h3>Start Location</h3>

    <p>Hemi Matenga reserve, entrance on Tui Cres (Between #13 & #15).</p>

    <h3>Distance to Hut</h3>
    <p>250m</p>

    <h3>Your Directions</h3>

    <p>
      Head up sealed driveway to start of bush walk and follow main track along
      stream. About 5 – 10 metres up from the gate there is a rough track up to
      your left. Scramble up there and you reach a ferny plateau. Head towards
      the big tree with the pink triangle marker on it. You can see the hut from
      there, take a selfie, then make your own way to the hut.
    </p>

    <h3>Nearest Train Station</h3>
    <p>1.2km from Waikanae Station</p>

    <h3>Safety Notes</h3>

    <p>
      Once off track the forest floor is uneven with many ferns and tree roots,
      watch your footing. This hut has one of the shortest walk through the bush
      and is great for littlies. It is also a great location to bike to either
      from Waikanae train station (5 min ride) or from further afield (25 mins
      to Kaitawa Hut, or an hour to Ōtaki Hut)
    </p>

    <p>
      <OutboundLink
        href="https://drive.google.com/open?id=1zEAg01WcKjX2Fj5nh--lc9jQHIuTsj44"
        target="_blank"
        rel="noopener noreferrer"
        className="big-link"
      >
        Download for offline use
      </OutboundLink>
    </p>
  </Fragment>
)

export default directions
