import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Audio from "../../../components/audio/audio"
import hutAudio from "./hut-audio.mp3"
import gif from "./fictionGif.mp4"

const hutFiction = (
  <>
    <video
      autoPlay
      loop
      muted
      playsInline
      src={gif}
      style={{ width: "100%" }}
    ></video>
    <div className="u-side-pad u-bottom-pad">
      <h3 className="heading--big">The Hut Maker</h3>
      <h4 className="heading--small">By Chris Maclean</h4>
      <p>
        So you want to know about the huts? Well, you’ve come to the right
        person. In that book of his, <em>Crusader Fox King</em>, Ian Mackersey
        called me Taranui but my name’s really Joe, Joe Gibbs.
      </p>
      <p>
        Writers like to disguise people and places, don’t they? Make them
        different. Fair enough. But I drew the line at being called a sheep
        stealer. For seventy years, I lived off the bush, venison mostly but
        once in a while a possum stew, too. I’d tell the trampers that it was
        deer and half the time they believed me! Never stolen a sheep, though.
      </p>
      <p>
        I didn’t know Mackersey. They say he was one of the young ones who came
        to the hut during the war. I don’t recall him, yet now his story is what
        people believe…
      </p>
      <p>
        I first saw these hills from the top of a tree, a tall rimu above the
        Tauherenikau that I climbed for a better view. I once had a garden
        there, with fruit trees and veges, until the bloody possums got into the
        enclosure. But I still loved living there, so unlike working on the
        wharves.
      </p>
      <p>
        No one found it easy up there. They learnt early on what the tops could
        do, when Harold Freeman died, back in 1922. The papers blamed it on the
        war, said it had weakened his heart, but that was only part of the
        story. They should’ve turned back, it was crazy to push on. Maybe if
        Fred Vosseler, from the Tararua Tramping Club, had been with them, they
        would’ve gone back. Who knows?
      </p>
      <p>
        But when Kime died, well, it was clear that the hills could kill. I was
        one of the searchers. We found him after five days, a bundle of khaki in
        the tussock. Fred saw him first, and gave him a tot from his flask. Then
        we carried him back to Alpha hut, put him by the fire, gave him some
        whisky. Kime’s face went red, then suddenly pale. Just a small sigh, as
        he slipped away. Esmond was his first name, but I only found that out
        after he died.
      </p>
      <p>
        Once you might say was bad luck. But twice in two years? Fred was
        furious. Took it personally, felt the whole sorry business gave his club
        a bad name.
      </p>
      <p>
        But mostly people’s thoughts turned to shelter, huts. Somewhere safe,
        out of the weather. Somewhere to get warm.
      </p>
      <p>
        The Tararua club built one up on Hector. Pukemoumou, the hill of
        desolation, the Maoris called it. People called it a dogbox, but it was
        better than that. Flagstones on the floor and a door that shut snugly.
        No window, though. Then they planted pines around it, for firewood, they
        said. I could’ve told them they wouldn’t survive. Nothing survives up
        there. After a few years, even the dogbox blew away.
      </p>
      <p>
        It was in 1923 that me and Jack Fisk got going on a hut just below Table
        Top. It was Willie Field, the MP, who got the government to stump up
        money. Put in his own money too, a hundred pounds.
      </p>
      <p>
        We had horses to help with the loads. Loved them like women. Gave them
        names, stroked them and dressed their hooves after a tough trip up from
        the Otaki Forks. Jack and me, we cut the timber from the bush. Then we
        sawed the logs into lengths, using the pit saw we made. Now that was
        something, especially if you were the one down in the pit, with sawdust
        in your eyes all the time. We took turns down there.
      </p>
      <p>
        Jack was the best sawyer I ever worked with. He got that blade moving so
        sweetly sometimes. Together we could cut timber faster than anyone. Then
        we clad the framing with corrugated iron carried up by the horses,
        strapped to their saddles. We laid lines of logs in the mud holes to
        make it easier for the mares, but the water always won.
      </p>
      <p>
        The city slickers loved coming up that track, though. They didn’t mind
        the mud. On Friday nights some even headed up by torchlight, keen to
        make the most of the weekend. Boys and girls, fresh and eager. Like my
        niece Gladys. She’s the closest to a daughter that I’ve got. Good with
        horses, is Gladys. Older men and women too, office workers. I’d
        sometimes see them eating their lunch down on the wharves. But in the
        hills they looked different.
      </p>
      <p>
        Then, a few years later, my greatest feat. They called the hut Kime,
        because his father gave Fred fifty quid. And a Bible. Everything came up
        on the horses. The summer before they’d plodded across the top of the
        South Island, taking tourists from Tophouse down to the West Coast.
        Gladys brought them over on the ferry from Nelson. And her brother Ralph
        came too, now that Jack had gone.
      </p>
      <p>
        It was hot that summer. We were lucky. Sometimes summer doesn’t come at
        all up here. Young Wally Neill got it all on his new camera. He was a
        bloody marvel, that kid. Said he grew up in a tent, but I didn’t believe
        him until he showed me a photo. His parents let him pitch a tent up the
        back of their section. He lived there for more than ten years. Finally ,
        Fred let him join the club, even though he was still too young. Then the
        kid was away!
      </p>
      <p>
        He rubbed shoulders with the best – Bill Davidson, Jim Butcher, Les
        Adkin and Norm Elder. But Norm was the only one to get a hut named after
        him. I never figured out if they did that because of his map – it was
        the first one of the ranges – or the silly scarf he wore wrapped round
        his head. Looked like an old woman. But nobody seemed to care and they
        all listened carefully when he took out his pipe and talked.
      </p>
      <p>
        Norm’s knowledge came from books. Mine came from the bush. My real
        classroom, you could say, was in the hills, while his was in a
        university, in Wellington. We still meet sometimes on the street. It
        feels strange, a bit awkward actually, and yet here in the hills, we
        share a cuppa and a smoke like brothers.
      </p>
      <p>
        The next hut I built for them was my third, or if we count extending
        Alpha hut, my fourth. ‘My last hurrah’, Fred used to call it. A
        two-storey beauty beside the Tauherenikau. In my valley. I wasn’t keen
        this time, but Fred was all for it. A clubhouse in the hills, he
        reckoned. Can’t argue with that. Mid-winter parties and barn dances,
        though I’d often slip away to my secret campsite up Canyon Creek. One of
        the young fellas, the one who worked in the Tax Department, told me
        Tauherenikau meant ‘house made of nikau’. He said Hau called it that,
        the ancestor who named all the rivers down this way. Hau’s house.
      </p>
      <p>
        I made Hau’s house my home. Lots of people came to visit, but Frank was
        my favourite. Thoughtful and gentle, never wanted to kill anything.
        Frank looked out for me, even wrote about me in the paper, but he told
        the truth, unlike Mackersey. Even after that pretty English girl caught
        Frank’s attention, he still kept an eye on me. After they got married, I
        sometimes went out to their place at Days Bay for Sunday lunch. A long
        walk for a roast, but the closest I got to having a family.
      </p>
      <p>
        That’s just the way it worked out. Not sure why. They said I bagged so
        many deer because I could think like one. But women? Comes a time you
        realise it’s not going to happen. Could never work them out. Some times
        it’s lonely out here on my own. I think of what might’ve been. A son or
        a daughter, maybe both. But what would their world be like?
      </p>
      <p>
        A week ago, two youngsters came to see me, asking me about the future. I
        can’t even think of the future, while the past is so fresh in my mind.
        Perhaps the two aren’t so different, though I didn’t tell them that.
      </p>
      <p>
        That was one thing Mackersey got right about me in his book – the
        hunting bits were spot on. He must’ve done a bit himself, to write like
        that. But it’s huts, not hunting, you want to know about…
      </p>
      <p>
        Twenty years after Crusader Fox King was published in England, the
        Sinclair brothers, Bill, Bob and Jim, built another Tararua hut, up on
        Kapakapanui. They loved to hunt up there. But Bob always said avoid it
        in the roar, when strangers came with their guns and their dangerous
        ways. Hunters built it, in a clearing blasted out with dynamite.
      </p>
      <p>
        Six months after they hit the last nail, the Sinclair brothers
        disappeared at sea. Vanished, fishing beyond Kapiti. No debris, no
        flotsam. Nothing left but their hut, a headstone in the hills. Fifty
        years later, the Sinclair kids built a memorial seat down by the
        estuary, halfway between the mountains and the island. Smooth
        macrocarpa, with a back rail carved in the shape of Kapiti.
      </p>
      <p>
        I remember visiting Fred in hospital. Covered in bruises from falling
        out of his bed.
      </p>
      <p>
        This old guy who taught so many how to live in the hills, once climbed
        Cook himself, couldn’t even get back under his sheets.
      </p>
      <p>
        I wish I hadn’t gone. Wish I’d kept Fred strong in my mind, as a
        mountain man, instead.
      </p>
      <p>
        I sit on the Sinclairs’ seat looking out to the island. Norm likened it
        to a sleeping crocodile, only, in his typical way, he called it a
        ‘slumbering Leviathan’. Behind me, the hills beckon. What I wouldn’t
        give to get back there, just for a few days in the old hut.
      </p>
      <p>
        <em>
          By Chris Maclean with thanks to Shaun Barnett, Jan FitzGerald and Alan
          Wehipeihana for their crucial contributions.
        </em>
      </p>
      <h3 className="heading--small">Further reading</h3>
      <p>
        Shaun Barnett and Chris Maclean,{" "}
        <em>Leading the Way – 100 Years of the Tararua Tramping Club</em>,
        Tararua Tramping Club and Potton &amp; Burton, Nelson, 2019.
      </p>
      <p>
        Ian Mackersey, <em>Crusader Fox King</em>, Robert Hale Ltd, London,
        1955.
      </p>
      <p>
        Chris Maclean, <em>Tararua – the story of a mountain range</em>,
        Whitcombe Press, Wellington, 1994.
      </p>

      <p>
        <OutboundLink
          href="https://drive.google.com/open?id=1obxj8mXQrF2-OpviPK0nu90TeFGw_BRo"
          target="_blank"
          rel="noopener noreferrer"
          className="big-link"
        >
          Download ‘The Hut Maker’ by Chris Maclean
        </OutboundLink>
      </p>
      <Audio audio={hutAudio} />
    </div>
    <div className="about-the-author u-side-pad">
      <h3 className="heading--small about-the-author__heading">
        About the author
      </h3>
      <p>
        Chris Maclean is a Waikanae based writer and publisher with a keen
        interest in the outdoors.
      </p>
      <p>
        An early career as a stained-glass artist led to his first book, with
        co-author Jock Phillips, In the light of the past - stained glass
        windows in New Zealand houses (1983). The pair then produced a sequel,
        The Sorrow and the Pride - New Zealand war memorials (1990).
      </p>
      <p>
        In the meantime, Chris also wrote and published his first local history,
        Waikanae - past &amp; present, (1988) co-authored with his mother, Joan
        Maclean. The success of this volume encouraged him to write more about
        the local landscape in Tararua - the story of a mountain range (1994).
        Both these books were published by his imprint, the Whitcombe Press,
        reviving his family’s publishing interest started by his great
        grandfather, George Whitcombe, who founded Whitcombe &amp; Tombs in
        1882.
      </p>
      <p>
        Another landscape history, Kapiti, followed in 1999, and won the Montana
        Book Award for History (2000).
      </p>
      <p>
        Chris wrote his first biography, John Pascoe, in 2003; then Te Hono -
        The Connections recalling the life of the Maori artist, John Bevan Ford
        (2008). However, the latter was abandoned due to legal complications. In
        the interim, Chris wrote another local history, Wellington - Telling
        Tales, an account of the capital during the 20th century.
      </p>
      <p>
        He returned to biography in 2013 with Stag Spooner - Wild Man from the
        Bush, before embarking on Tramping - A New Zealand History (2014)
        written with Shaun Barnett. Their authorial collaboration continued with
        a companion volume, Leading The Way - 100 years of the Tararua Tramping
        Club (2019).
      </p>
      <p>
        The stories behind the writing and publication of a dozen titles during
        four decades during which the place of books changed dramatically, is
        recalled in Chris’s 2018 memoir A Way with Words.
      </p>
    </div>
  </>
)

export default hutFiction
