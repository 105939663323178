import React, { Fragment } from "react"
import Layout from "../../../components/layout/layout"
import directionsContent from "../../../components/hut-data/hut5/directions"
import hutFictionContent from "../../../components/hut-data/hut5/hutFiction"
import aboutContent from "../../../components/hut-data/hut5/about"
import HutPageLayout from "../../../components/hutPageLayout/hutPageLayout"
import HutMap from "../../../components/hutMap/hutMap"
import { AccordionContent } from "../../../components/accordion/accordion"
import { GeolocationContext } from "../../../AppState"
import hutTitle from "../../../components/hut-data/hut5/hut-title.svg"
import aboutImg from "../../../components/hut-data/hut5/about-img.jpg"
import doodle from "../../../components/hut-data/hut5/hut-doodle.jpg"

const accordionContent: AccordionContent[] = [
  {
    title: "Directions",
    inner: (
      <Fragment>
        <img src={doodle} className="width-full" alt="" />
        <div className="u-side-pad u-top-pad">{directionsContent}</div>
      </Fragment>
    ),
    backgroundColour: "#fae297",
  },
  {
    title: "Found it",
    inner: "",
    backgroundColour: "#ffcc99",
  },
  {
    title: "Hut fiction",
    inner: hutFictionContent,
    backgroundColour: "#d0dce2",
    locked: true,
  },
  {
    title: "About",
    inner: (
      <Fragment>
        <img src={aboutImg} alt="" className="width-full" />
        {aboutContent}
      </Fragment>
    ),
  },
]

function Hut() {
  return (
    <Layout title="Waikanae Hut">
      <GeolocationContext.Consumer>
        {context => {
          const { foundHuts } = context
          return (
            <div className="map__info-container">
              <HutMap
                isHutPage
                height="320px"
                foundHuts={foundHuts}
                bounds={[
                  [175.07077774822505, -40.8729977217481],
                  [175.08157958720005, -40.87869276897879],
                ]}
              />
            </div>
          )
        }}
      </GeolocationContext.Consumer>
      <HutPageLayout
        hutId="hut5"
        heading="Waikanae Hut"
        headingImg={hutTitle}
        headingColour="#009999"
        description="A backcountry hut gets us through a night in the wilderness but what you see is not always what you get."
        accordionContent={accordionContent}
        unlockCode="7ARARUA"
      />
    </Layout>
  )
}

export default Hut
