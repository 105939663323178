import React from "react"

const about = (
  <div className="u-side-pad u-top-pad">
    <h3 className="heading--big">Follow the Journey</h3>
    <p>
      <a
        href="https://www.instagram.com/stories/highlights/17862935416638703/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <strong>Watch moments from the build</strong>
      </a>{" "}
      (open in web browser)
    </p>
    <p>
      <a
        href="https://www.instagram.com/urbanhutclub/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <strong>See images of others who have visited</strong>
      </a>
    </p>
    <h3 className="heading--small">
      Share your hut selfies with us by using these tags:
    </h3>
    <p>
      INSTAGRAM:{" "}
      <a
        href="https://www.instagram.com/urbanhutclub/"
        target="_blank"
        rel="noopener noreferrer"
      >
        @UrbanHutClub
      </a>
    </p>
    <p>
      FACEBOOK:{" "}
      <a
        href="https://www.facebook.com/keminiko"
        target="_blank"
        rel="noopener noreferrer"
      >
        @keminiko
      </a>
    </p>
    <p>
      ANYWHERE:{" "}
      <a
        href="https://www.instagram.com/explore/tags/urbanhutclub/"
        target="_blank"
        rel="noopener noreferrer"
      >
        #UrbanHutClub
      </a>
    </p>
    <h3 className="heading--big">Hut credits</h3>
    <h3 className="heading--small">Designed by</h3>
    <p>Kemi Niko & Co.</p>
    <h3 className="heading--small">Commissioned by</h3>
    <p>New Zealand Festival of the Arts</p>
    <h3>Partnered by</h3>
    <p>Springload</p>
    <h3 className="heading--small">Ngā mihi</h3>
    <ul className="credits__list">
      <li>Kāpiti Coast District Council</li>
      <li>Creative New Zealand</li>
      <li>New Zealand Community Trust</li>
    </ul>
    <h3>Built by</h3>
    <ul className="credits__list">
      <li>Kemi Niko & Co.</li>
    </ul>
    <p>January 2020</p>
    <h3 className="heading--small">With Support From</h3>
    <ul className="credits__list">
      <li>Department of Conservation</li>
      <li>Danielle Jordan</li>
      <li>Wrigley family</li>
      <li>Ngā Manu Nature Reserve</li>
      <li>Helen Forrest</li>
      <li>Chris Maclean</li>
    </ul>
    <h3>Materials Donated by</h3>
    <ul className="credits__list">
      <li>Dulux</li>
      <li>New Zealand Festival Workshop</li>
    </ul>
  </div>
)

export default about
